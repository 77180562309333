<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName" @tab-change="handleTabChange">
      <v-tab
        v-for="(item, i) in allUserRight"
        :title="item.text"
        :key="item + i"
      >
        <Apply7 v-if="item.text == '報考資格審查'" ></Apply7>
        <Apply13 v-if="item.text == '學測成績上傳'"></Apply13>
        <Apply8 v-if="item.text == '籍屬身分審查'"></Apply8>
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import { store } from "@/store/global.js";
import "vue-nav-tabs/themes/vue-tabs.css";
import queryString from "query-string";
import { domainObject } from "@/constant.js";
import Apply7 from "./DtcVueTabs102/Apply7";
import Apply13 from "./Apply1";
import Apply8 from "./DtcVueTabs102/Apply7";

const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";

const titles = [
  "報考資格審查",
  "學測成績上傳",
  "籍屬身分審查",
];

export default {
  name: "totalSpendTabsVue",
  data() {
    return {
      titles,
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [
        { text: "報考資格審查", isActive: false, functionId: "10032" },
        { text: "學測成績上傳", isActive: false, functionId: "10010" },
        { text: "籍屬身分審查", isActive: false, functionId: "10033" },
      ],
      userRights: [],
      domainObject,
    };
  },
  components: {
    VueTabs,
    VTab,
    Apply7,
    Apply13,
    Apply8,
  },
  methods: {
    handleTabChange(tabIndex, newTab, oldTab) {
      if (tabIndex == 0) {
        this.$root.$emit("get-Newest-LocalstuData");
      } else if (tabIndex == 1) {
        this.$root.$emit("get-Newest-Notregister");
      }
    },
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.allUserRight = this.allUserRight.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.allUserRight = this.allUserRight.filter((s) => s.isActive);
      //   let allData = (this.allData = JSON.parse(localStorage.dtxBuget));
      //   allData = allData.find((s) => s.MenuName == "系統管理")
      //     ? allData.find((s) => s.MenuName == "系統管理")
      //     : {};
      //   if (Boolean(allData.SubMenu)) {
      //     this.allUserRight = !allData.SubMenu.length
      //       ? []
      //       : allData.SubMenu.find((s) => s.MenuName == "在地養成資料維護");
      //     if (this.allUserRight.SubMenu.length) {
      //       this.allUserRight.SubMenu.forEach((s) => {
      //         this.userRights.push(s.MenuName);
      //       });
      //     }
      //   }
    },
  },
  watch: {},
  async mounted() {
    // setTimeout(() => {
    //   this.showTab1 = true;
    //   this.show = false;
    // }, 0);

    this.setUserRight();
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
